import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import title_image from '../images/titles/about-us.jpg'


const pageTitle = "About Us";
const pageSlug = "about-us";


class BusinessConsulting extends React.Component{
    render(){
      return(
        <Layout>

<section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Constant and consistent change is what distinguishes us</h6>
        </div>
</section>

              
<section id="p_technology_services" className="section static-page page-content">
                  <div className="container">
                      <div className="columns">
                          <div className="column is-12 content-area">
                              
                              <h3>We strive to match our corporate culture with our corporate strategy.</h3>
                              <i>Have fun, help your friends, and do your best at all times.</i>
                              <span className="break"></span>
                              <span className="break"></span>
                              <p>This phrase is what ties us together, not only is it the basis of our consulting methodologies, but it has also established the cornerstone for our workplace culture. It unifies our community and creates an atmosphere of continuous cooperation, we enjoy our work at Monera Technologies, we take pride in creating tailored solutions that suit your business needs. Read more about who we are and what the Monera Technologies group means.</p>
                              <p>We agree that our consumers as well as our workers deserve the highest quality products and an outstanding experience. We strive to create and provide our customers with a unique and unparalleled experience, for us, that's why we exist. We are inspired at our heart to aim for success on an ongoing basis.</p>
                              <span className="break"></span>
                              <h5>Our Journey </h5>
                              <p>We have tried to create a professional consulting service from the beginning of our company. The company, while offering a premium service, is always open to change and how we can develop ourselves and make a better effect on others.</p>
                              <span className="break"></span>
                              <h5>Our Workplace Beliefs</h5>
                              <p>Our confidence in the workplace is due to us offering the maximum value for the lowest price. Good professional advice because a team project is at its heart consulting. Our experts are working with you, not just for you. In order to create new ideas and to discover new viewpoints. This will allow your company to be much more streamlined and will result in a significant technological boost across all fields that will give you a competitive advantage.</p>
                              <span className="break"></span>
                              <h5>What We Offer </h5>
                              <p>We are constantly working to make a positive impact on all our clients, for our partners we are seeking to provide a high-quality service that will enhance their value creation, in effect increase their profitability, and develop sustainable partnerships. That is our core passion.</p>
                              <span className="break"></span>
                              <h5>Our Leadership</h5>
                              <p>
                              In Monera Technologies, we're committed to doing what's best for our clients and people in a way that's special and no other company can match. Our decision-makers have a large amount of expertise, so they weigh all the viewpoints available before reaching a conclusion. This commitment to quality and commitment to creating lasting relationships is what allows us to provide you with a quality solution. Our company wants to serve you better.
                              </p>
                              <span className="break"></span>
                              <span className="break"></span>
                              <span className="break"></span>
                              <hr />
                              <h3>Find out more about how we're working with you to help you get the consulting experience</h3>
                              <p>
                              Check out our <a href="">Latest Blogs</a> to find some relevant information. We hope that the material can provide any useful insights for your organization to help you make the right decision.
                              </p>

 
                  
                </div>
            </div>
        </div>
      </section>



</Layout>
    )
  }
}

export default BusinessConsulting
